import { useEffect, useState } from 'react'
import css from 'classnames'
import { SVGArrowToScroll } from '~/shared/ui'

const GoToTop = () => {
  const [showButton, setShowButton] = useState(false)

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <button
      onClick={scrollToTop}
      className={css(
        'flex justify-center items-center w-10 h-10 border border-[#242424] border-opacity-50 bg-[#2563EB] rounded-lg ',
        {
          hidden: !showButton,
        },
      )}
    >
      <div>
        <SVGArrowToScroll strokeColor="white" />
      </div>
    </button>
  )
}

export default GoToTop
