import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { useEffect, useMemo, useRef } from 'react'
import css from 'classnames'
import { useStore } from '~/app/store'

import { Card, TableContainer } from '~/shared/ui'
import { Input } from '~/shared/ui/input'
import { PermissionsMap } from '~/features/role-add'
import GoToTop from '~/features/scroll-to-top'
import { createRoleColumns } from '~/features/role-add/config'

export const RoleEdit = observer(() => {
  const { t } = useTranslation()
  const { usersStore } = useStore()
  const {
    validationErrors,
    setEditRole,
    roleById,
    permissions,
    isLoading,
    getRoleById,
  } = usersStore
  const listBottom = useRef<HTMLDivElement>(null)
  const anchor = useMemo(
    () => <div ref={listBottom} className={css({ hidden: isLoading })} />,
    [isLoading],
  )
  const params = useParams()

  useEffect(() => {
    getRoleById(params.id as string)
  }, [params.id, getRoleById])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditRole(e.target.value)
  }
  const typedPermissions = permissions ? (permissions as PermissionsMap) : {}

  const data = Object.entries(typedPermissions).map(([name, actions]) => ({
    name,
    permissions: actions,
  }))
  const columns = createRoleColumns()

  return (
    <div>
      {!isLoading && roleById && (
        <Card className="flex w-[100%]  flex-col gap-3">
          <p className="text-[20px] text-[#242424]">Информация профиля</p>

          <Input
            className="rounded"
            label={t('name')}
            value={roleById?.name}
            onChange={handleChange}
            labelType="floating"
            helperText={validationErrors['user.name']}
            status={validationErrors['user.name'] ? 'error' : undefined}
          />

          <div />
          <p className="text-[20px] text-[#242424]">Разрешения</p>
          <div className="w-full border border-[#E0E0E0] rounded-t-xl rounded-b-md">
            <TableContainer
              columns={columns}
              data={data}
              isLoading={isLoading}
              className="w-full "
            />
          </div>
          {anchor}
          <div className="fixed bottom-8 left-7">
            <GoToTop />
          </div>
        </Card>
      )}
      {!isLoading && !roleById && 'Роль не найдена'}
    </div>
  )
})
