/* eslint-disable no-param-reassign */
import { useRef, useState } from 'react'
import css from 'classnames'
import { XYCoord, useDrag, useDrop } from 'react-dnd'
import { Link } from 'react-router-dom'
import { NestedCategories } from '~/shared/api'
import { moveElement, replaceCategoriesById } from '../../lib/helpers'
import { DropZone } from './drop-zone'
import { SubItem2 } from './sub-item-2'
import { SVGDragIndicator, ArrowToDown, SVGEye, SVGPencil } from '~/shared/ui'

interface Props {
  data: NestedCategories
  subList: NestedCategories[]
  list: NestedCategories[]
  index: number
  parentItem: NestedCategories
  setList: (data: NestedCategories[]) => void
  updateList: (draggingId: string, targetItem: NestedCategories) => void
  updated: () => void
}

export const SubItem = ({
  data,
  index,
  subList,
  parentItem,
  setList,
  list,
  updateList,
  updated,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const dragPreviewRef = useRef<HTMLDivElement | null>(null)
  const [expanded, setExpanded] = useState(false)

  const [, drop] = useDrop({
    accept: 'sub-category',
    hover: (item: NestedCategories & { index: number }, monitor) => {
      if (!dragPreviewRef.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) return

      const hoveredRect = dragPreviewRef.current.getBoundingClientRect()
      const hoverMiddleX = (hoveredRect.right - hoveredRect.left) / 2 // Calculate the horizontal middle
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2 // Calculate the vertical middle

      const mousePosition = monitor.getClientOffset() as XYCoord
      const hoverClientX = mousePosition.x - hoveredRect.left
      const hoverClientY = mousePosition.y - hoveredRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return // Don't move if dragging downwards above vertical middle
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return // Don't move if dragging upwards below vertical middle
      if (hoverClientX < hoverMiddleX && hoverClientY !== hoverMiddleY) return // Don't move if dragging right and not exactly on the vertical middle
      if (hoverClientX > hoverMiddleX && hoverClientY !== hoverMiddleY) return // Don't move if dragging left and not exactly on the vertical middle

      const newData = moveElement(subList, dragIndex, hoverIndex)

      const newList = replaceCategoriesById(list, parentItem.id, newData)

      setList(newList)
      updated()

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'sub-category',
    item: { ...data, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(ref)
  drop(preview(dragPreviewRef))

  return (
    <div
      ref={dragPreviewRef}
      className={css(
        'sub-category bg-gray-100  overflow-hidden min-w-[320px]  xl:min-w-[49.5%] border border-[#E0E0E0] rounded-lg ',
        {
          'h-auto': expanded,
          'h-[56px]': !expanded,
          'opacity-50': isDragging,
        },
      )}
    >
      <div className="flex items-center justify-between gap-3 h-[56px]">
        <span
          ref={ref}
          className="flex item-center justify-center p-3 cursor-grab active:cursor-grabbing"
        >
          <SVGDragIndicator />
        </span>
        <div className="flex items-center">
          <h2
            className={css('text-[18px] truncate mx-auto font-medium', {
              'opacity-50': !data.showInMenu,
            })}
          >
            {data.localizations[0].name || data.name}
          </h2>

          {data.showInMenu ? null : (
            <div
              className={css(
                'flex items-center mx-3 gap-2 p-3 pr-0  text-gray-400',
              )}
            >
              <div className={css({ 'opacity-60': !data.showInMenu })}>
                {' '}
                <SVGEye icon="visibilityOff" />
              </div>

              <p className={css({ 'opacity-60': !data.showInMenu })}>Скрыт</p>
            </div>
          )}
        </div>

        <Link
          to={`/categories/${data.id}/edit`}
          className="flex items-center justify-center group mr-auto"
        >
          <div
            className={css(
              'text-gray-400 cursor-pointer  group-hover:text-gray-600',
              {
                'opacity-50': !data.showInMenu,
              },
            )}
          >
            <SVGPencil />
          </div>
        </Link>
        <div className="mr-3">
          <ArrowToDown
            className={css('cursor-pointer transition-all mr-4 duration-75', {
              'rotate-180': expanded,
            })}
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      </div>

      <div className="px-3 pb-3">
        <hr />

        <div className="mt-3 flex flex-col gap-3 mb-3">
          {data?.categories?.map((sub, idx) => (
            <SubItem2
              key={sub?.id}
              data={sub}
              list={list}
              updateList={updateList}
              subList={data.categories || []}
              index={idx}
              setList={setList}
              parentItem={data}
              updated={updated}
            />
          ))}
        </div>

        <div className="flex min-w-[350px] h-[100px] flex-1 w-full mt-3">
          <DropZone updateList={updateList} parentElement={data} />
        </div>
      </div>
    </div>
  )
}
