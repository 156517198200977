import { FC } from 'react'
import { useDrop } from 'react-dnd'
import css from 'classnames'
import { Props } from './types'

import { CategoryItem } from '../dnd/category'
import { NestedCategories } from '~/shared/api'
import {
  findById,
  insertElementWithShift,
  recursiveMove,
  recursiveRemove,
} from '../../lib/helpers'
import { isEqual } from '~/shared/lib'

export const CategoriesList: FC<Props> = ({ data, setList, updated }) => {
  const update = (id: string, targetEl: NestedCategories) => {
    const elem = findById(data, id)

    if (elem) {
      setList((prev) => {
        const newData = recursiveMove(prev, elem.id, targetEl.id)

        if (!isEqual(prev, newData)) {
          updated()
          return newData
        }

        return prev
      })
    }
  }

  const [collectTop, dropTopDrag] = useDrop({
    accept: ['sub-category', 'sub-category-2', 'sub-category-3'],

    drop: (item: NestedCategories) => {
      const filtered = recursiveRemove(data, item.id)
      const newData = insertElementWithShift(filtered, 0, item)

      setList(newData)

      updated()
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  })

  return (
    <div className="flex flex-col gap-3 w-full overflow-y-auto">
      {data.length ? (
        <div
          ref={dropTopDrag}
          className={css('p-1 w-full rounded', {
            'bg-gray-300 opacity-70': collectTop.isOver,
          })}
        />
      ) : null}

      {data.map((item, i) =>
        item.name ? (
          <CategoryItem
            key={item.id}
            data={item}
            index={i}
            categoriesList={data}
            updateList={update}
            setList={setList}
            updated={updated}
          />
        ) : null,
      )}
    </div>
  )
}
