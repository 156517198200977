/* eslint-disable react/jsx-props-no-spreading */
import { flexRender } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { TableHeadProps } from './types'
import { tableHead } from './config'
import lan from '~/app/locale/languages/en.json'
import '~/app/styles/index.css'

export function TableHead<T>({
  className,
  columns = [],
  aroundwithout = true,
}: TableHeadProps<T>) {
  const { t } = useTranslation()

  return (
    <thead className={(tableHead({ className }), 'w-full')}>
      {columns.map((headerGroup) => (
        <React.Fragment key={headerGroup.id}>
          <tr>
            {headerGroup.headers.map((header, index) => {
              const isFirstHeader = index === 0
              const isLastHeader = index === headerGroup.headers.length - 1
              const headerGroupLength = header.headerGroup.headers.length === 4
              const headerGroupLengthRoles =
                header.headerGroup.headers.length === 5
              const headerStyles = {
                width: header.getSize(),
                borderTopLeftRadius:
                  aroundwithout && isFirstHeader ? '10px' : '0',
                borderTopRightRadius:
                  aroundwithout && isLastHeader ? '10px' : '0',
                textAlign: 'left' as const,
              }

              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  {...(aroundwithout ? { aroundwithout: 'true' } : {})}
                  style={{
                    ...headerStyles,
                    textAlign: header.id === 'name2' ? 'left' : undefined,
                  }}
                  scope="col"
                  className={`text-xs font-normal text-left   text-gray-500 bg-[#EBEBEB] ${
                    (header.id === 'ACountry' && 'text-center px-3 py-2.5') ||
                    (header.id === 'active' && 'text-right px-6 py-2.5') ||
                    header.id === 'delete' ||
                    header.id === 'the number of necessary goods' ||
                    header.id === 'priceString' ||
                    (header.id === 'manufacturer' && 'px-3 py-1 ') ||
                    'px-6 py-2.5'
                  } ${header.id === 'orderNumber' ? 'whitespace-normal' : ''} ${
                    header.id === 'the number of necessary goods'
                      ? 'whitespace-normal text-center'
                      : ''
                  }     ${
                    headerGroupLength
                      ? 'bg-white text-[#707070] font-normal capitalize'
                      : 'bg-[#EBEBEB]'
                  } }  ${
                    headerGroupLengthRoles
                      ? 'bg-[#E0E0E0] text-[#707070] font-normal capitalize text-center'
                      : 'bg-[#EBEBEB]'
                  }`}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        t(
                          header.column.columnDef
                            .header as keyof (typeof lan)['translation'],
                        ),
                        header.getContext(),
                      )}
                </th>
              )
            })}
          </tr>
          <tr>
            <td
              colSpan={headerGroup.headers.reduce(
                (acc, header) => acc + header.colSpan,
                0,
              )}
            />
          </tr>
        </React.Fragment>
      ))}
    </thead>
  )
}
