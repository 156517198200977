/* eslint-disable no-param-reassign */
import { useRef, useState } from 'react'
import css from 'classnames'
import { XYCoord, useDrag, useDrop } from 'react-dnd'
import { Link } from 'react-router-dom'
import { NestedCategories } from '~/shared/api'
import { DropZone } from './drop-zone'
import { SubItem3 } from './sub-item-3'
import { moveElement, replaceCategoriesById } from '../../lib/helpers'
import { SVGDragIndicator, ArrowToDown, SVGEye, SVGPencil } from '~/shared/ui'

export interface Props {
  data: NestedCategories
  subList: NestedCategories[]
  list: NestedCategories[]
  index: number
  parentItem: NestedCategories
  setList: (data: NestedCategories[]) => void
  updateList: (draggingId: string, targetItem: NestedCategories) => void
  updated: () => void
}

export const SubItem2 = ({
  data,
  index,
  parentItem,
  list,
  subList,
  setList,
  updateList,
  updated,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const dragPreviewRef = useRef<HTMLDivElement | null>(null)

  const [expanded, setExpanded] = useState(false)

  const [, drop] = useDrop({
    accept: 'sub-category-2',

    hover: (item: NestedCategories & { index: number }, monitor) => {
      if (!ref.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) return

      const hoveredRect = ref.current.getBoundingClientRect()
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2
      const mousePosition = monitor.getClientOffset() as XYCoord
      const hoverClientY = mousePosition.y - hoveredRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      const newData = moveElement(subList, dragIndex, hoverIndex)
      const newList = replaceCategoriesById(list, parentItem.id, newData)

      setList(newList)
      updated()
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'sub-category-2',
    item: { ...data, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(ref)
  drop(preview(dragPreviewRef))

  return (
    <div
      ref={dragPreviewRef}
      className={css(
        'category active:overflow-hidden bg-white w-full overflow-hidden rounded-lg border border-[#E0E0E0]',
        {
          'h-[56px]': !expanded,
          'h-auto': expanded,
          'opacity-50': isDragging,
        },
      )}
      data-id={data.id}
    >
      <div className="flex items-center justify-between gap-3 h-[55px]">
        <div className="flex items-center">
          <div
            ref={ref}
            className="flex item-center justify-center p-3 cursor-grab active:cursor-grabbing"
          >
            <SVGDragIndicator />
          </div>
          <h2
            className={css('text-[18px] truncate', {
              'opacity-50': !data.showInMenu,
            })}
          >
            {data.localizations[0].name || data.name}
          </h2>
          {data.showInMenu ? null : (
            <div
              className={css('flex items-center gap-2 mx-3  text-gray-400', {
                'opacity-60': !data.showInMenu,
              })}
            >
              <SVGEye icon="visibilityOff" />
              <p className={css({ 'opacity-60': !data.showInMenu })}>
                Скрыт
              </p>{' '}
            </div>
          )}
          <Link
            to={`/categories/${data.id}/edit`}
            className="ml-3 flex items-center group justify-center"
          >
            <div
              className={css(
                'text-gray-400 cursor-pointer  group-hover:text-gray-600 ',
                {
                  'opacity-50': !data.showInMenu,
                },
              )}
            >
              <SVGPencil />
            </div>
          </Link>
        </div>
        <div onClick={() => setExpanded(!expanded)} className="cursor-pointer ">
          <div className="px-4">
            <ArrowToDown
              className={css(
                'cursor-pointer ml-auto mr-3 transition-all duration-75',
                {
                  'rotate-180': expanded,
                },
              )}
              onClick={() => setExpanded(!expanded)}
            />
          </div>
        </div>
      </div>

      <div className="px-3 pb-3">
        <hr />

        <div className="my-3 flex gap-3 mb-4">
          <div className="flex flex-col gap-3 flex-1">
            {data?.categories?.map((item, idx) => (
              <SubItem3
                key={item?.id}
                data={item}
                list={list}
                subList={data.categories || []}
                index={idx}
                setList={setList}
                parentItem={data}
                updated={updated}
              />
            ))}
          </div>
        </div>

        <div className="flex min-w-[350px] h-[100px] flex-1 w-full">
          <DropZone updateList={updateList} parentElement={data} />
        </div>
      </div>
    </div>
  )
}
