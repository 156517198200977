import { Dispatch, SetStateAction, useRef, useState } from 'react'
import css from 'classnames'
import { XYCoord, useDrag, useDrop } from 'react-dnd'
import { Link } from 'react-router-dom'
import { NestedCategories } from '~/shared/api'
import {
  findById,
  insertElementWithShift,
  recursiveRemove,
} from '../../lib/helpers'
import { SubItem } from './sub-item-1'
import { DropZone } from './drop-zone'
import { SVGDragIndicator, ArrowToDown, SVGEye, SVGPencil } from '~/shared/ui'

export interface Props {
  data: NestedCategories
  updateList: (draggingId: string, targetItem: NestedCategories) => void
  categoriesList: NestedCategories[]
  index: number
  setList: Dispatch<SetStateAction<NestedCategories[]>>
  updated: () => void
}

export const CategoryItem = ({
  data,
  index,
  categoriesList,
  setList,
  updateList,
  updated,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const dragPreviewRef = useRef<HTMLDivElement | null>(null)

  const [expanded, setExpanded] = useState(false)

  const [, drop] = useDrop({
    accept: 'category',

    hover: (item: NestedCategories & { index: number }, monitor) => {
      if (!dragPreviewRef.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) return

      const isOverShallow = monitor.isOver({ shallow: true })

      if (!isOverShallow) return

      const hoveredRect = dragPreviewRef.current.getBoundingClientRect()
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2
      const mousePosition = monitor.getClientOffset() as XYCoord
      const hoverClientY = mousePosition.y - hoveredRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      const newItems = [...categoriesList]
      const [draggedItem] = newItems.splice(dragIndex, 1)
      newItems.splice(hoverIndex, 0, draggedItem)

      setList(newItems)
      updated()

      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'category',
    item: { ...data, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(ref)
  drop(preview(dragPreviewRef))

  const [{ canDrop, isOver }, dropParent] = useDrop({
    accept: ['sub-category', 'sub-category-2', 'sub-category-3'],

    drop: (item: NestedCategories) => {
      if (ref.current) {
        const idx = Number(ref.current.getAttribute('data-index'))

        const filtered = recursiveRemove(categoriesList, item.id)
        const newData = insertElementWithShift(filtered, idx, item)

        setList(newData)

        updated()
      }
    },

    canDrop: (item) => {
      const el = findById(data.categories || [], item.id)

      return !el
    },

    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  })

  const [collect, dropDrag] = useDrop({
    accept: ['sub-category', 'sub-category-2', 'sub-category-3'],

    drop: (item: NestedCategories) => {
      const filtered = recursiveRemove(categoriesList, item.id)
      const newData = insertElementWithShift(filtered, 1, item)

      setList(newData)

      updated()
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  })

  return (
    <div data-id={data.id}>
      <div ref={dragPreviewRef}>
        <div
          className={css(
            'category relative transition-all active:overflow-hidden bg-white w-full overflow-hidden rounded-lg border border-[#E0E0E0]',
            {
              'h-[56px]': !expanded,
              'h-[auto]': expanded,
              'opacity-50': isDragging || (isOver && canDrop),
            },
          )}
          data-index={index}
        >
          <div
            className="flex items-center relative h-[55px] "
            data-parent={`parent-${data.id}`}
            ref={dropParent}
          >
            <div
              ref={ref}
              data-index={index}
              className="flex item-center justify-center p-3 cursor-grab active:cursor-grabbing"
            >
              <SVGDragIndicator />
            </div>

            <div className="flex items-center w-full pr-3">
              <h2
                className={css('text-[18px]', {
                  'opacity-50': !data.showInMenu,
                })}
              >
                {data.localizations[0].name || data.name}
              </h2>

              {data.showInMenu ? null : (
                <div
                  className={css(
                    'flex items-center gap-2 p-3 ml-3  text-gray-400',
                  )}
                >
                  <div className={css({ 'opacity-60': !data.showInMenu })}>
                    {' '}
                    <SVGEye icon="visibilityOff" />{' '}
                  </div>

                  <p className={css({ 'opacity-60': !data.showInMenu })}>
                    Скрыт
                  </p>
                </div>
              )}

              <Link
                to={`/categories/${data.id}/edit`}
                className="flex items-center mr-auto ml-3 justify-center cursor-pointer group"
              >
                <div
                  className={css('text-gray-400  group-hover:text-gray-600 ', {
                    'opacity-50': !data.showInMenu,
                  })}
                >
                  <SVGPencil />
                </div>
              </Link>
              <span
                onClick={() => setExpanded(!expanded)}
                className="cursor-pointer flex-1 ml-auto p-1.5 text-end flex items-center justify-center"
              >
                <div
                  className={css(
                    'cursor-pointer ml-auto transition-all duration-75',
                    {
                      'rotate-180': expanded,
                    },
                  )}
                >
                  <ArrowToDown />
                </div>
              </span>
            </div>
          </div>

          <div className={css('p-3')}>
            <hr className="mt-2" />

            <div className="my-3 flex gap-3 justify-between">
              <div className="flex gap-3">
                <div className="flex gap-3 flex-wrap justify-between">
                  {data?.categories?.map((item, idx) => (
                    <SubItem
                      setList={setList}
                      parentItem={data}
                      data={item}
                      key={item.id}
                      subList={data.categories || []}
                      list={categoriesList}
                      index={idx}
                      updateList={updateList}
                      updated={updated}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className="flex min-w-[350px] h-[100px] flex-1 w-full">
              <DropZone updateList={updateList} parentElement={data} />
            </div>
          </div>
        </div>
      </div>

      {categoriesList.length === 1 ? (
        <div
          ref={dropDrag}
          className={css('h-[56px] mt-2 w-full rounded', {
            'bg-gray-200  opacity-70': collect.isOver,
          })}
        />
      ) : null}
    </div>
  )
}
