import { AxiosResponse } from 'axios'
import { apiService } from '../../api-base'
import {
  StoreByIdModel,
  Currencie,
  Languages,
  PaymentZone,
  CounriesSelect,
  PaymentSystem,
  FakeShiping,
  Regions,
  MarketPlace,
  PickUpPointsResponce,
  AnnouncementsResponse,
  UpdateStoreMainRequest,
  UpdateStoreSMTPRequest,
  UpdateStorePaymentSystemsRequest,
  UpdateStoreShippingRequest,
  Announcements,
  PickUpPointsData,
  PickUpPointPayload,
  AddPaymentZonePayload,
  AnnouncementsReqest,
  Stores,
} from './types'

export class StoresService {
  static getStoreById(id: string): Promise<AxiosResponse<StoreByIdModel>> {
    return apiService.get<AxiosResponse<StoreByIdModel>>(`/stores/${id}`)
  }

  static createStore(data?: unknown): Promise<AxiosResponse<{ id: string }>> {
    return apiService.post<AxiosResponse<{ id: string }>>(`/stores`, data)
  }

  static updateStoreMain(
    id: string,
    data?: UpdateStoreMainRequest,
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/stores/${id}`, data)
  }

  static updateStoreSMTP(
    id: string,
    data?: UpdateStoreSMTPRequest,
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/stores/${id}/smtp`, data)
  }

  static updateStorePaymentSystems(
    id: string,
    data?: UpdateStorePaymentSystemsRequest,
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/stores/${id}/payments`, data)
  }

  static updateStoreShipping(
    id: string,
    data?: UpdateStoreShippingRequest,
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/fake-shipping/${id}`, data)
  }

  static updatePaymentZone(
    id: string,
    data?: { zone: PaymentZone },
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/payment/zones/${id}`, data)
  }

  static deleteFakeShipping(id: string): Promise<AxiosResponse<FakeShiping>> {
    return apiService.delete<AxiosResponse<FakeShiping>>(`/fake-shipping/${id}`)
  }

  static addFakeShipping(storeId: string): Promise<AxiosResponse<FakeShiping>> {
    return apiService.post<AxiosResponse<FakeShiping>>(`/fake-shipping`, {
      storeId,
    })
  }

  static addAnnouncements(
    data: AnnouncementsReqest,
  ): Promise<AxiosResponse<AnnouncementsResponse>> {
    return apiService.post<AxiosResponse<AnnouncementsResponse>>(
      `/announcements`,
      data,
    )
  }

  static addPickUpPoint(
    data: PickUpPointPayload,
  ): Promise<AxiosResponse<PickUpPointsResponce>> {
    return apiService.post<AxiosResponse<PickUpPointsResponce>>(`/pickup`, data)
  }

  static addPaymentZone(
    data: AddPaymentZonePayload,
  ): Promise<AxiosResponse<PaymentZone>> {
    return apiService.post<AxiosResponse<PaymentZone>>(`/payment/zones`, data)
  }

  static deleteAnnouncements(
    id: string,
  ): Promise<AxiosResponse<Announcements>> {
    return apiService.delete<AxiosResponse<Announcements>>(
      `/announcements/${id}`,
    )
  }

  static deletePickUpPoints(
    id: string,
  ): Promise<AxiosResponse<PickUpPointsData>> {
    return apiService.delete<AxiosResponse<PickUpPointsData>>(`/pickup/${id}`)
  }

  static deletePaymentZones(id: string): Promise<AxiosResponse<PaymentZone>> {
    return apiService.delete<AxiosResponse<PaymentZone>>(`/payment/zones/${id}`)
  }

  static updatePickUpPoint(
    id: string,
    data?: PickUpPointPayload,
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/pickup/${id}`, data)
  }

  static updateAnnouncement(
    id: string,
    data?: AnnouncementsReqest,
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/announcements/${id}`, data)
  }

  static getCurrencies(): Promise<AxiosResponse<Currencie[]>> {
    return apiService.get<AxiosResponse<Currencie[]>>('/stores/currencies')
  }

  static getLanguages(): Promise<AxiosResponse<Languages[]>> {
    return apiService.get<AxiosResponse<Languages[]>>('/stores/languages')
  }

  static getPaymentZoneByStoreId(
    id: string,
  ): Promise<AxiosResponse<PaymentZone[]>> {
    return apiService.get<AxiosResponse<PaymentZone[]>>(
      `/payment/zones?storeId=${id}`,
    )
  }

  static getCountries(): Promise<AxiosResponse<CounriesSelect[]>> {
    return apiService.get<AxiosResponse<CounriesSelect[]>>('/countries')
  }

  static getPaymentSystem(): Promise<AxiosResponse<PaymentSystem[]>> {
    return apiService.get<AxiosResponse<PaymentSystem[]>>(`/stores/payments`)
  }

  static getStores(): Promise<AxiosResponse<Stores[]>> {
    return apiService.get<AxiosResponse<Stores[]>>(`/stores`)
  }

  static getPickUpPoints(
    id: string,
  ): Promise<AxiosResponse<PickUpPointsResponce[]>> {
    return apiService.get<AxiosResponse<PickUpPointsResponce[]>>(
      `pickup?storeId=${id}`,
    )
  }

  static getFakeShiping(id: string): Promise<AxiosResponse<FakeShiping[]>> {
    return apiService.get<AxiosResponse<FakeShiping[]>>(
      `fake-shipping/?storeId=${id}`,
    )
  }

  static getRegions(code: string): Promise<AxiosResponse<Regions[]>> {
    return apiService.get<AxiosResponse<Regions[]>>(`countries/${code}/regions`)
  }

  static getMarketplace(): Promise<AxiosResponse<MarketPlace[]>> {
    return apiService.get<AxiosResponse<MarketPlace[]>>(`/stores/marketplaces`)
  }

  static getAnnouncements(
    id: string,
  ): Promise<AxiosResponse<AnnouncementsResponse[]>> {
    return apiService.get<AxiosResponse<AnnouncementsResponse[]>>(
      `/announcements?storeId=${id}`,
    )
  }

  static getSecret(
    id: string,
    paymentCode: string,
    secretKey: string,
  ): Promise<AxiosResponse<{ secret: string }>> {
    return apiService.get<AxiosResponse<{ secret: string }>>(
      `/stores/${id}/payments/${paymentCode}/secrets/${secretKey}`,
    )
  }

  static getMarketSecret(
    id: string,
    marketplace: string,
  ): Promise<
    AxiosResponse<{ secrets: { value: string | null; name: string }[] }>
  > {
    return apiService.get<
      AxiosResponse<{ secrets: { value: string | null; name: string }[] }>
    >(`/stores/${id}/marketplaces/${marketplace}/secrets`)
  }

  static updateMarketSecret(
    id: string,
    marketplace: string,
    data?: unknown,
  ): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(
      `/stores/${id}/marketplaces/${marketplace}/secrets`,
      data,
    )
  }

  static updatePaymentZoneSecret(
    id: string,
    code: string,
    secretName: string,
    data?: unknown,
  ): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(
      `/stores/${id}/payments/${code}/secrets/${secretName}`,
      data,
    )
  }
}
