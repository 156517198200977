/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
import { useStore } from '~/app/store'
import { Checkbox } from '~/shared/ui'

const PermissionCheckbox = observer((props: { actionName: string }) => {
  const { usersStore } = useStore()
  const location = useLocation()
  const isEditPage = location.pathname.includes('/edit')
  const action = isEditPage ? 'update' : 'add'

  const {
    saveSelectedpermissions,
    setSaveSelectedPermissions,
    setSelectedPermissions,
    selectedPermissions,
  } = usersStore
  const handleClick = () => {
    if (action === 'update') {
      setSelectedPermissions(props.actionName)
    } else {
      setSaveSelectedPermissions(props.actionName)
    }
  }
  return (
    <Checkbox
      onClick={() => handleClick()}
      checked={
        action === 'update'
          ? selectedPermissions.includes(props.actionName)
          : saveSelectedpermissions.includes(props.actionName)
      }
    />
  )
})

const createRoleColumns = (): ColumnDef<any>[] => {
  const columns: ColumnDef<any>[] = [
    {
      header: 'name2',
      accessorKey: 'name2',
      cell: (row) => <p>{row.cell.row.original.name} </p>,
      maxSize: 10,
    },
  ]

  const actionTypes = ['create', 'update', 'delete', `get`]

  actionTypes.forEach((action) => {
    columns.push({
      header: action === 'get' ? 'get list' : action,
      accessorKey: action,
      cell: (row) => {
        const rowPerms =
          (row.cell.row.original.permissions as {
            originalName: string
          }[]) || []
        const hasAction = rowPerms.some(
          (perm: { originalName: string }) =>
            perm.originalName && perm.originalName.includes(action), // Проверяем, содержит ли `perm.name` текущий `action`
        )
        const findPerm = rowPerms.find((perm) =>
          perm.originalName.includes(action),
        )
        const actionName = findPerm?.originalName

        return hasAction ? (
          <div className="flex justify-center">
            <PermissionCheckbox actionName={actionName as string} />
          </div>
        ) : (
          <div className="flex justify-center text-gray-500 ">-</div>
        )
      },
      maxSize: 100,
    })
  })

  return columns
}

export { createRoleColumns }
