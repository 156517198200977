/* eslint-disable react/require-default-props */
import { useDrop } from 'react-dnd'
import css from 'classnames'
import { useTranslation } from 'react-i18next'
import { NestedCategories } from '~/shared/api'
import { SVGDriveFileMove } from '~/shared/ui'
import { useToast } from '~/shared/lib'

interface Props {
  parentElement: NestedCategories
  updateList: (id: string, data: NestedCategories) => void
  onDrop?: () => void
}

export const DropZone = ({ parentElement, updateList, onDrop }: Props) => {
  const { t } = useTranslation()
  const toast = useToast()

  const isChild = (category: NestedCategories, targetId: string): boolean => {
    if (!category.categories) return false
    return category.categories.some(
      (child) => child.id === targetId || isChild(child, targetId),
    )
  }

  const isParentOrChildCategory = (
    draggedItem: NestedCategories,
    targetItem: NestedCategories,
  ): boolean => {
    if (draggedItem.id === targetItem.id) {
      return true // Нельзя переместить категорию в саму себя
    }

    // Проверяем, является ли draggedItem потомком targetItem
    if (isChild(targetItem, draggedItem.id)) {
      return true
    }

    // Проверяем, является ли targetItem потомком draggedItem
    if (isChild(draggedItem, targetItem.id)) {
      return true
    }

    return false
  }

  const [collectedProps, drop] = useDrop(() => ({
    accept: ['category', 'sub-category', 'sub-category-2', 'sub-category-3'],
    drop: (item: NestedCategories) => {
      if (isParentOrChildCategory(item, parentElement)) {
        toast.open(
          'Нельзя переместить категорию в себя или свою подкатегорию',
          'danger',
        )
        return
      }
      updateList(item.id, parentElement)
      onDrop?.()
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }))

  return (
    <div
      ref={drop}
      className={css(
        'w-full max-h-[200px] h-auto font-light border-gray-300 dashed px-6 rounded-lg flex items-center gap-3 justify-center',
        {
          'bg-[#D1D1D1] ': collectedProps.isOver,
        },
      )}
    >
      <SVGDriveFileMove />
      <span className="text-gray-600 text-[14px]">{t('dndCategory')}</span>
    </div>
  )
}
