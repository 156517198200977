/* eslint-disable no-nested-ternary */
import { FC, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import css from 'classnames'
import { motion } from 'framer-motion'
import { useMediaQuery } from 'usehooks-ts'
import { useTranslation } from 'react-i18next'
import { NavSub } from '../nav-sub'
import { MenuModel } from '~/shared/lib'

interface Props {
  item: MenuModel
  index: number
}

export const NavMenuItem: FC<Props> = ({ item, index }) => {
  const { t } = useTranslation()
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const matches = useMediaQuery('(max-width: 768px)')
  const { pathname } = useLocation()

  const isSubMenu = Boolean(item?.subLinks && item?.subLinks?.length > 0)
  const activeLink = item.subLinks?.some((link) => link.link === pathname)
  useEffect(() => {
    setOpenIndex(null)
  }, [pathname])

  useEffect(() => {
    if (!matches) setOpenIndex(null)
  }, [matches])

  const handleHeadingClick = (e: React.MouseEvent) => {
    if (matches && isSubMenu) {
      e.preventDefault()
      setOpenIndex((prev) => (prev === index ? null : index))
    }
  }
  return (
    <div className="p-0">
      <li
        className={css('inline-block group w-full cursor-pointer', {
          '[&>a>div>span]:opacity-100 [&>a>div>span]:w-full': activeLink,
        })}
      >
        <Link
          to={item.link}
          onClick={(e) => handleHeadingClick(e)}
          className={css(
            'group text-black-700 py-3 md:py-4 px-5 flex justify-between items-center',
            { 'hover:bg-slate-100': !matches },
          )}
        >
          <div className="relative flex items-center gap-2 ">
            <span className="icon hidden md:block absolute bottom-[-10px] opacity-0 group-hover:opacity-100 transition left-0 right-0 w-full bg-blue-600 h-[3px] rounded" />
            {t(item.localeTextName)}
          </div>
        </Link>

        {/* Подменю */}
        {isSubMenu && matches ? (
          <motion.div
            className={css(' overflow-hidden', {
              hidden: openIndex !== index,
            })}
            animate={
              openIndex === index ? { height: 'fit-content' } : { height: 0 }
            }
          >
            {item.subLinks?.map((link) => (
              <div key={link.localeTextName} className="pl-5 py-2">
                <Link to={link.link} className="text-gray-600">
                  {t(link.localeTextName)}
                </Link>
              </div>
            ))}
          </motion.div>
        ) : isSubMenu && !matches ? (
          <NavSub item={item} />
        ) : null}
      </li>
    </div>
  )
}
