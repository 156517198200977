/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { MultiValue } from 'react-select'
import { UsersService } from '~/shared/api/services/users'
import {
  UserById,
  EditUserById,
  ListUser,
  Roles,
  Loaders,
  CreateUser,
  User,
} from '~/shared/api/services/users/types'
import { Handler, handleAxiosError, mapDataForSelect } from '~/shared/lib'
import { SelectOption } from '~/shared/ui'

export class UsersStore {
  createUserState: UserById = {
    name: '',
    email: '',
    password: '',
    newPassword: '',
    roles: [],
    settings: {},
    id: undefined,
  }

  isLoading = false

  offset = 0

  limit = 20

  loaders: Loaders = {
    editUser: false,
    createRole: false,
    roleEdit: false,
    roleDelete: false,
    createUser: false,
    userDelete: false,
    blockUser: false,
    findUser: false,
    changePassword: false,
    setRoles: false,
    permissions: false,
  }

  filters = {
    name: '',
    email: '',
  }

  q = ''

  activeTab = 1

  isEndUserList = false

  isListLoading = false

  isMoreLoading = false

  permissions = null

  usersList: ListUser[] = []

  roleById: Roles | null = null

  editedUser: EditUserById | null = null

  roles: Roles[] = []

  roleName = ''

  currentUser: User | null = null

  selectedPermissions: string[] = []

  saveSelectedpermissions: string[] = []

  selectedRoles: MultiValue<SelectOption> = []

  validationErrors: Record<string, string | undefined> = {}

  tempHandlers: Handler[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setTempHandler = (data: Handler[]) => {
    this.tempHandlers = data
  }

  activeLoader = (key: keyof Loaders) => {
    this.loaders[key] = true
  }

  closeLoader = (key: keyof Loaders) => {
    this.loaders[key] = false
  }

  setRoleName = (value: string) => {
    this.roleName = value
  }

  setCreateUser = (name: keyof UserById, value: any) => {
    this.createUserState[name] = value
  }

  getUsersById = async (id: string) => {
    this.isLoading = true
    try {
      const { data } = await UsersService.getUsersById(id)
      runInAction(() => {
        this.editedUser = data as EditUserById
        if (this.editedUser && this.editedUser.roles) {
          const mappedRoles = mapDataForSelect(
            this.editedUser?.roles,
            'id',
            'name',
          )

          this.selectedRoles = mappedRoles as MultiValue<SelectOption>
        }
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data
      }
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  getRoleById = async (id: string) => {
    this.isLoading = true
    try {
      const { data } = await UsersService.getRoleById(id)
      runInAction(() => {
        this.roleById = data
        this.selectedPermissions = data.permissions.map((perm) => perm.name)
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data
      }
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  setSelectedRoles = (roles: MultiValue<SelectOption>) => {
    runInAction(() => {
      this.selectedRoles = roles
    })
  }

  setActiveTab(tabId: number) {
    this.activeTab = tabId
  }

  setSelectedPermissions = (permission: string) => {
    if (this.selectedPermissions.includes(permission)) {
      this.selectedPermissions = this.selectedPermissions.filter(
        (perm) => perm !== permission,
      )
    } else {
      this.selectedPermissions = [...this.selectedPermissions, permission]
    }
  }

  setSaveSelectedPermissions = (permission: string) => {
    if (this.saveSelectedpermissions.includes(permission)) {
      this.saveSelectedpermissions = this.saveSelectedpermissions.filter(
        (perm) => perm !== permission,
      )
    } else {
      this.saveSelectedpermissions = [
        ...this.saveSelectedpermissions,
        permission,
      ]
    }
  }

  resetSaveSelectedPermissions = () => {
    this.saveSelectedpermissions = []
    this.roleName = ''
  }

  resetSelectedPermissions = () => {
    this.selectedPermissions = []
    this.roleName = ''
  }

  resetUser = (storeName: 'createUserState' | 'editedUser') => {
    this[storeName] = {
      name: '',
      email: '',
      password: '',
      newPassword: '',
      settings: {},
      id: undefined,
      roles: [],
    }
  }

  setEditUser = (name: keyof EditUserById, value: never) => {
    if (this.editedUser) this.editedUser[name] = value
  }

  setEditRole = (value: string) => {
    if (this.roleById) {
      this.roleById.name = value
    }
  }

  editUser = async (id: string, name: string) => {
    try {
      this.activeLoader('editUser')
      await UsersService.editUser(id, {
        name,
      })

      runInAction(() => {
        this.editedUser = { ...this.editedUser, name }
        this.validationErrors = {}
      })
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      runInAction(() => {
        this.closeLoader('editUser')
      })
    }
  }

  updateRole = async () => {
    if (!this.roleById?.id) return

    try {
      this.activeLoader('roleEdit')
      await UsersService.updateRole(this.roleById.id, {
        name: this.roleById.name,
        permissions: this.selectedPermissions,
      })
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      runInAction(() => {
        this.closeLoader('roleEdit')
      })
    }
  }

  deleteRole = async (id: string) => {
    if (!this.roleById?.id) return

    try {
      this.activeLoader('roleDelete')
      await UsersService.deleteRole(id)
      runInAction(() => {
        this.validationErrors = {}
      })
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      runInAction(() => {
        this.closeLoader('roleDelete')
      })
    }
  }

  deleteUser = async (id: string) => {
    if (!this.editedUser?.id) return

    try {
      this.activeLoader('userDelete')
      await UsersService.deleteUser(id)
      this.getUsers('init')
      runInAction(() => {
        this.validationErrors = {}
      })
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      runInAction(() => {
        this.closeLoader('userDelete')
      })
    }
  }

  setQ = (query: string) => {
    this.q = query
  }

  getUsers = async (action: 'q' | 'loadMore' | 'init') => {
    if (this.isEndUserList && action === 'loadMore') return

    if (['q', 'init'].includes(action)) {
      this.offset = 0
      this.limit = 20
    }

    runInAction(() => {
      this.isListLoading = ['q', 'init'].includes(action)
      this.isMoreLoading = action === 'loadMore'
    })

    try {
      const params: Record<string, any> = {
        limit: this.limit,
        offset: this.offset,
      }
      if (this.q?.trim()) {
        params.q = this.q
      }

      const { data } = await UsersService.getUsers({ params })
      runInAction(() => {
        this.offset += this.limit
        this.isEndUserList = data.length < this.limit
        if (action === 'loadMore') {
          this.usersList = [...this.usersList, ...data]
        } else {
          this.usersList = data
        }
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.isListLoading = false
        this.isMoreLoading = false
      })
    }
  }

  findUserByEmail = async (email: string) => {
    try {
      this.activeLoader('findUser')
      const { data } = await UsersService.getUsers({ params: { q: email } })
      runInAction(() => {
        const findUserData = data[0]
        if (findUserData) {
          this.currentUser = findUserData as User
        }
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('findUser')
      })
    }
  }

  getPermission = async () => {
    try {
      this.activeLoader('permissions')

      const { data } = await UsersService.getPermission()

      runInAction(() => {
        this.permissions = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('permissions')
      })
    }
  }

  getRoles = async () => {
    runInAction(() => {
      this.isListLoading = true
    })

    try {
      const { data } = await UsersService.getRoles()

      runInAction(() => {
        this.roles = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.isListLoading = false
      })
    }
  }

  createRole = async (data: { name: string; permissions: string[] }) => {
    try {
      this.activeLoader('createRole')

      await UsersService.createRole(data)

      this.resetSelectedPermissions()
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      this.closeLoader('createRole')
    }
  }

  changePassword = async (user_id: string, new_password: string) => {
    try {
      this.activeLoader('changePassword')
      await UsersService.changePassword(user_id, { new_password })
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      this.closeLoader('changePassword')
    }
  }

  setRoles = async (user_id: string, roles: string[]) => {
    try {
      this.activeLoader('setRoles')
      await UsersService.setRoles(user_id, { roles })
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      this.closeLoader('setRoles')
    }
  }

  blockUser = async (id: string, enabled: boolean) => {
    try {
      this.activeLoader('blockUser')

      const data = { enabled }
      await UsersService.blockUser(id, data)
      this.getUsersById(id)
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      this.closeLoader('blockUser')
    }
  }

  setCreateUserRoles = (roles: MultiValue<SelectOption>) => {
    this.createUserState.roles = roles.map((role) => ({
      id: role.value,
      name: role.label,
    })) as Roles[]
  }

  createUser = async (userData: CreateUser) => {
    try {
      this.activeLoader('createUser')
      const { data } = await UsersService.createUser(userData)
      await UsersService.setRoles(data.id, {
        roles: userData.roles,
      })
      await this.getUsers('init')
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      this.closeLoader('createUser')
    }
  }
}

export default UsersStore
