import { observer } from 'mobx-react-lite'
import { usePageTitle } from '~/shared/lib'
import { PageTitle } from '~/shared/ui'
import FileInput from '~/shared/ui/input-file'

const UploadReport = observer(() => {
  usePageTitle({
    title: 'Загрузить отчет',
  })

  return (
    <div className="ml-6">
      <div className=" my-6">
        <PageTitle title="Загрузить отчет" />
      </div>

      <div className="flex justify-between w-[57%] h-[48px]">
        <div className="w-[500px]">
          <FileInput label="Загрузите файл" placeholder="Файл не выбран" />
        </div>
      </div>
    </div>
  )
})

export default UploadReport
